<template>
  <div>
    <div class="max-w">
      <h2>[moody@programming]$ ls ~/projects | xargs -d '\n' xdg-open</h2>
    </div>

    <AppCard
      title="Eve Online Market Watcher"
      :description="eveMarketWatcherDescription"
      link="https://play.google.com/store/apps/details?id=com.enzoaphexprod.evemarketwatcher"
      image="evemarketwatcher.png"
    />

    <div class="max-w">
      <h2 class="current">[moody@programming]$</h2>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppCard from "../components/AppCard.vue";

const eveMarketWatcherDescription =
  "Eve Market Watcher is your essential companion for navigating the complex markets of Eve Online.\nThis app allows you to tailor searches to specific Eve Online regions, ensuring your trading strategies are as efficient as possible.";
</script>

<style lang="scss" scoped></style>
