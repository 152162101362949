<template>
  <main class="main">
    <router-view />
    <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/data">Data Policy</router-link>
      <router-link to="/imprint">Imprint</router-link>
    </nav>
  </main>
</template>

<style lang="scss">
*,
*::before,
*::after {
  padding: 0;
  font-family: "Courier New", monospace !important;
  margin: 0;
  background: #001;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
  overflow-y: scroll;
  color: #ffe;
}

a {
  color: #ffe;
}

main {
  background: #001;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

nav {
  display: flex;
  justify-content: end;
  padding: 1rem;
}

nav a {
  margin-left: 1rem;
}

.max-w {
  text-align: left;
  width: 100%;
  display: flex;
  padding: 2rem;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

h2 {
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 10px;
}

.current::after {
  content: "";
  width: 18px;
  height: 25px;
  background: #ffe;
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
}

.text {
  text-align: left;
  margin: 0 2.2rem;
}
@media all and (max-width: 769px) {
  .header,
  .max-w {
    padding: 1rem;
  }

  .text {
    margin: 0 1rem;
  }
}
</style>
