<template>
  <div class="card">
    <img
      :src="`img/icons/${props.image}`"
      :title="props.title"
      :alt="props.title"
    />

    <div style="text-align: left">
      <h3>
        {{ props.title }}
      </h3>
      <p>
        {{ props.description }}
        <a style="display: block" :href="props.link" target="_blank">Inspect</a>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";

const props = defineProps<{
  title: string;
  image: string;
  description: string;
  link: string;
}>();
</script>

<style scoped lang="scss">
.card {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2.5rem;

  & > div {
    margin-left: 0.5rem;
  }
}

img {
  height: 120px;
  width: 120px;
}

h3 {
  padding-bottom: 0.8rem;
}

a {
  padding-top: 0.5rem;
}

@media all and (max-width: 769px) {
  .card {
    flex-direction: column;
    padding: 0.5rem;
  }

  img {
    padding-bottom: 0.5rem;
  }
}
</style>
